import simpleRestProvider from 'ra-data-simple-rest';
import { addLocksMethodsBasedOnALockResource } from '@react-admin/ra-realtime';
import { addTreeMethodsBasedOnChildren } from '@react-admin/ra-tree';

import addRealtimeMethodsWithFakeTransport from './addRealtimeMethodsWithFakeTransport';
import addSearchMethod from './addSearchMethod';

import { fetchUtils } from 'ra-core';
import addInfraMethods from './addInfraMethods';
import tokenManager from '../tokenManager';

const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = tokenManager.getToken();
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
        return fetchUtils.fetchJson(url, options);
    } else {
        return tokenManager.getRefreshedToken().then(gotFreshToken => {
            console.log('Refress: ' + gotFreshToken);
            if (gotFreshToken) {
                options.headers.set(
                    'Authorization',
                    `Bearer ${tokenManager.getToken()}`
                );
            }
            return fetchUtils.fetchJson(url, options);
        });
    }
};

const compose = (...funcs: any) =>
    funcs.reduce(
        (a: any, b: any) =>
            (...args: any) =>
                a(b(...args)),
        (arg: any) => arg
    );

const dataProvider = simpleRestProvider(
    `${process.env.REACT_APP_INFRA_API}`,
    httpClient
);

const restProvider = compose(
    addLocksMethodsBasedOnALockResource,
    addRealtimeMethodsWithFakeTransport,
    addTreeMethodsBasedOnChildren,
    addSearchMethod,
    addInfraMethods
)(dataProvider, httpClient);

const customProviders = {
};

const customMethods = {};

const delayedDataProvider = new Proxy(restProvider, {
    get: (target, name, self) => {
        if (name === 'then') {
            return self;
        }

        return (resource, params) => {
            //console.log("resource: " + resource + "?" + params);
            let provider = restProvider;
            if (customProviders[resource]) {
                provider = customProviders[resource];
            }

            if (customMethods[resource]) {
                provider = {
                    ...provider,
                    ...customMethods[resource](provider),
                };
            }

            return new Promise(resolve =>
                resolve(provider[name](resource, params))
            );
        };
        /*return (resource: string, params: any) => {
            return new Promise(resolve =>
                setTimeout(
                    () => resolve(restProvider[name](resource, params)),
                    200
                )
            );
        };*/
    },
});

export default delayedDataProvider;
