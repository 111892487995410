import * as React from 'react';
import { SxProps, Typography, Avatar, StepLabel } from '@mui/material';
import { memo } from 'react';

import { FieldProps, useRecordContext, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import lodash from 'lodash';
import user from '../user';
import group from '../school';

interface Props {
    createLink?: boolean;
    useIcon?: boolean;
    source: string;
    useLabel?: boolean;
    label?: string;
}

const iconType = (t) => (
    t == 'teacher' ? <user.iconTeacher/> :
        t == 'student' ? <user.iconStudent/> :
            t == 'group' ? <group.icon/> : <group.icon/>
)

const linkUrl = (t, g) => {
    let baseUrl = "https://oppimateriaalit-aspa-dev.otava.fi/#/";
    if (t == 'group') {
        return baseUrl + 'school?filter={%22parent_group%22%3A%22'+g+'%22}';
    } else {
        return baseUrl + t + '/' + g;
    }
}

const handleOnClik = (e, t, g) => {
    e.stopPropagation();
    window.location.href=linkUrl(t, g);
}

const SubscriberFullNameField = (props: Props) => {
    const translate = useTranslate();
    const subscription = useRecordContext();
    const record = subscription[props.source];

    return record ? (
        props.createLink ? 
            <>
            {props.useLabel && <StepLabel>{props.label ? translate(props.label, { smart_count: 1 }) : ""}</StepLabel>}
            <Link to="" /*{linkUrl(record.type, record.guid)}*/ onClick={(e) => handleOnClik(e, record.type, record.guid)}>
                    <Typography
                        variant="body2"
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        component="div"
                    >
                        {props.useIcon ? <Avatar style={{ width: '30px', height: '30px', marginRight: '5px' }}>{iconType(record.type)}</Avatar> : null}
                        {record.name}
                    </Typography>
            </Link></> : 
            <>
            {props.useLabel && <StepLabel>{props.label ? translate(props.label, { smart_count: 1 }) : ""}</StepLabel>}
            <Typography
                variant="body2"
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                component="div"
            >
                {props.useIcon ? <Avatar style={{ width: '30px', height: '30px', marginRight: '5px' }}>{iconType(record.type)}</Avatar> : null}
                {record.name}
            </Typography></>
    ) : null;
};

SubscriberFullNameField.defaultProps = {
    source: '',
    label: 'resources.subscription.fields.subscriber',
    createLink: false,
    useIcon: false,
    useLabel: false,
};

export default memo<Props>(SubscriberFullNameField);
