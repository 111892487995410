import * as React from 'react';
import { FC, useState, useEffect } from 'react';
//import { useFormState } from 'react-final-form';
import { useWatch } from 'react-hook-form';
import { useDataProvider, Loading, SelectInput, useRecordContext } from 'react-admin';
import { useQuery } from 'react-query';

import lodash from 'lodash';

interface Props {
    source: string;
    resource?: string;
    optionValue?: string;
    optionText?: string;
    allowEmpty?: boolean;
    url: string;
    // qparamkey?: string;
    // qparamval?: string;
    filter?: string;
    fullWidth?: boolean;
    alwaysOn?: boolean;
    label?: string;
}

interface ResItem {
    item_id: any;
    item_title: any;
}

const CustomSelectInput: FC<Props> = ({
    source,
    resource = '',
    optionValue = 'item_id',
    optionText = 'item_title',
    allowEmpty = true,
    url,
    // qparamkey,
    // qparamval = '',
    filter = '',
    fullWidth = true,
    alwaysOn = true,
    ...props
}) => {
    
    // const val = useWatch({ name: qparamval });
    // const params = val != undefined
    //                 ? qparamkey + '=' + val
    //                 : '';

    const record = useRecordContext();

    const params = filter != ''
                    ? 'filter={"' + filter + '": "' + record[filter] + '"}'
                    : '';
    
    const dataProvider = useDataProvider();
    // const [loading, setLoading] = useState(true);
    // const [customChoices, setCustomChoices] = useState<ResItem[]>();

    const { data } = useQuery(
        [url, 'getAny', params], 
        () => dataProvider.getAny(url, params)
    );

    if (!data) return null;

    const customChoices = data.data;
    // useEffect(() => {
    //     dataProvider
    //         .getAny(url, params)
    //         .then(({ data }) => {
    //             setCustomChoices(data);
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             setLoading(false);
    //         });
    // }, [params]);

    // if (loading) return <Loading />;
    if (!customChoices) return null;

    return (
        <SelectInput
            source={source}
            resource={resource}
            optionValue={optionValue}
            optionText={optionText}
            choices={customChoices}
            //allowEmpty={allowEmpty} tää pitää selvittää
            fullWidth={fullWidth}
            alwaysOn={alwaysOn}
            {...props}
        />
    );
};

export default CustomSelectInput;
