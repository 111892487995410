import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import MailIcon from '@mui/icons-material/MailOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch, SavedQueriesList } from 'react-admin';
//import { SavedQueriesList } from '@react-admin/ra-preferences';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
} from 'date-fns';

import LanguageIcon from '@mui/icons-material/Language';
import ApplicationIcon from '@mui/icons-material/Web';
import TargetPersonIcon from '@mui/icons-material/People';
import KouluasteetIcon from '@mui/icons-material/School';
import TypeIcon from '@mui/icons-material/LibraryBooks';
import StatusIcon from '@mui/icons-material/CheckCircle';

import CustomFilterList from '../controls/CustomFilterList';
import CustomFilterSearch from '../controls/CustomFilterSearch';

//import segments from '../segments/data';

const ProductListAside = () => (
    <Card
        sx={{
            display: {
                xs: 'none',
                md: 'block',
            },
            order: -1,
            flex: '0 0 15em',
            mr: 2,
            mt: 8,
            alignSelf: 'flex-start',
        }}
    >
        <CardContent sx={{ pt: 1 }}>
            <FilterLiveSearch source="q" variant='outlined' />
            <SavedQueriesList />
            <CustomFilterList
                url="extralist/product/applications"
                label="resources.product.filters.applications"
                source="applications"
                icon={<ApplicationIcon />}
            />
            <CustomFilterList
                url="extralist/product/kouluasteet"
                label="resources.product.filters.kouluasteet"
                source="kouluasteet"
                icon={<KouluasteetIcon />}
            />
            <CustomFilterList
                url="extralist/product/target_persons"
                label="resources.product.filters.target_persons"
                source="target_persons"
                icon={<TargetPersonIcon />}
            />
            <CustomFilterList
                url="extralist/product/languages"
                label="resources.product.filters.languages"
                source="language"
                icon={<LanguageIcon />}
            />
            <CustomFilterList
                url="extralist/product/statuses"
                label="resources.product.filters.status"
                source="status"
                icon={<StatusIcon />}
            />
            <CustomFilterList
                url="extralist/product/kvstatuses"
                label="resources.product.filters.kvstatus"
                source="saatavuudet"
                icon={<StatusIcon />}
            />
            <CustomFilterList
                url="extralist/product/types"
                label="resources.product.filters.type"
                source="product_type"
                icon={<TypeIcon />}
            />
            <CustomFilterList
                url="extralist/product/materialstorages"
                label="resources.product.filters.materialstorage"
                source="materialstorage"
                icon={<TypeIcon />}
                className="testi"
                // sx={{
                //     'MuiList-root': {
                //         display: 'none',
                //     },
                // }}
            />
            
        </CardContent>
    </Card>
);

export default ProductListAside;
