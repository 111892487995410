const tokenManager = () => {
    //let inMemoryJWT = null;
    let inMemoryJWT = window.localStorage.getItem('token');
    let isRefreshing = null;
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = '/api/auth/refresh_token';
    let refreshTimeOutId;

    const setLogoutEventName = name => (logoutEventName = name);
    const setRefreshTokenEndpoint = endpoint => (refreshEndpoint = endpoint);

    // This countdown feature is used to renew the JWT before it's no longer valid
    // in a way that is transparent to the user.
    const refreshToken = delay => {
        refreshTimeOutId = window.setTimeout(
            getRefreshedToken,
            delay * 1000 - 5000
        ); // Validity period of the token in seconds, minus 5 seconds
    };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    };

    // The method make a call to the refresh-token endpoint
    const getRefreshedToken = () => {
        const token = window.localStorage.getItem('token');
        const request = new Request(refreshEndpoint, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
            credentials: 'include',
        });

        isRefreshing = fetch(request)
            .then(response => {
                if (response.status !== 200) {
                    ereaseToken();
                    global.console.log('Token renewal failure');
                    return { token: null };
                }
                return response.json();
            })
            .then(({ token, tokenExpiry }) => {
                if (token) {
                    console.log('Token refress: ' + tokenExpiry + ' s.');
                    setToken(token, tokenExpiry);
                    return true;
                }
                console.log('Ei tänne');
                ereaseToken();
                return false;
            });

        return isRefreshing;
    };

    const getToken = () => inMemoryJWT;
    const setToken = (token, delay) => {
        inMemoryJWT = token;
        window.localStorage.setItem('token', token);
        refreshToken(delay);
        return true;
    };

    const ereaseToken = () => {
        inMemoryJWT = null;
        abordRefreshToken();
        window.localStorage.setItem(logoutEventName, Date.now());
        window.localStorage.removeItem('token');
        return true;
    };

    // This listener will allow to disconnect a session of ra started in another tab
    window.addEventListener('storage', event => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
        }
    });

    return {
        ereaseToken,
        getRefreshedToken,
        getToken,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
    };
};

export default tokenManager();
