import * as React from 'react';
import {
    Create,
    TextInput,
    TabbedForm,
    FormTab,
    SaveButton,
    Toolbar,
} from 'react-admin';
import {
    required,
} from 'react-admin';
import { Grid } from '@mui/material';

import { 
    LibraryBooks,
    Article,
    Podcasts,
    Attachment,
    DataObject,
    TransferWithinAStation,
    History,
 } from '@mui/icons-material';

const EditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
};

const ProductEdit = () => {

    return (
        <Create>
            <TabbedForm toolbar={<EditToolbar />} >
                <FormTab label="resources.product.tabs.details" icon={<Article />}>
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={8}>
                                <TextInput resource="product" source="name" validate={[required()]} fullWidth />
                            </Grid>
                        </Grid>
                    
                </FormTab>
                <FormTab label="resources.product.tabs.extra_details" icon={<LibraryBooks />} path="lisatiedot" disabled={true}>
                    
                </FormTab>
                <FormTab label="resources.product.tabs.redirect_details" icon={<Podcasts />} path="ohjaustiedot" disabled={true}>
                    
                </FormTab>
                {/* <FormTab label="resources.product.tabs.tuotelista_details" icon={<DataObject />} path="tuotelista" disabled={true}>
                    
                </FormTab> */}
                {/* <FormTab label="resources.subscription.tabs.modify_history" icon={<History />} path="muokkaus_historia" disabled={true}>

                </FormTab> */}
                {/* <FormTab label="resources.subscription.tabs.usage_history" icon={<TransferWithinAStation />} path="kaytto_historia" disabled={true}>

                </FormTab> */}
                <FormTab label="resources.product.tabs.sub_details" icon={<Attachment />} path="liitetyt_tuotteet" disabled={true}>
                    
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

// const filter = {parent_product_guid: '#guid#'};

// const filter2 = {exclude_child_of_guid: '#guid#'};


//const optionRenderer = choice => `${choice.name} / ${choice.public.category} (${choice.public.materialstorage.isbn})`;
// title={<ProductTitle />}
//const ProductTitle = () => <ProductFullNameField size="32" sx={{ margin: '5px 0' }} />;

const redirect: any = () => {
    return window.location.hash.replace('#', '');
}

export default ProductEdit;