import * as React from 'react';
import { useState } from 'react';
import { useRefresh, useEditContext, useListContext, useDeleteMany, useNotify, useUnselectAll, Confirm } from 'react-admin';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

const CustomUrlBulkDeleteButton = () => {

    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const parent = useEditContext();
    const list = useListContext();
    const notify = useNotify();
    
    const url = `${parent.resource}/${parent.record.guid}/${list.resource}`;
    
    const unselectAll = useUnselectAll(list.resource);

    const [deleteMany, { isLoading }] = useDeleteMany(
        url,
        { ids: list.selectedIds },
        {
            onSuccess: () => {
                refresh();
                unselectAll();
            },
            onError: error => notify(`Virhe poistettaessa: ${error}`, { type: 'warning' }),
        }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => {
        refresh();
        unselectAll();
        setOpen(false);
    }

    const handleConfirm = () => {
        deleteMany();
        setOpen(false);
    };

    return (
        <>
            <Button onClick={handleClick} disabled={isLoading} color='warning'><DeleteIcon /> Poista</Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Poista valitut alituotteet"
                content={"Haluatko varmasti poistaa valitsemasi alituotteet?"}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default CustomUrlBulkDeleteButton;
