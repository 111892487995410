import React, { FC, useState, useEffect } from 'react';
import { useRecordContext } from 'react-admin';
//import Chip from '@material-ui/core/Chip';
//import { FieldProps } from '../../types';
//import segments from '../segments/data';
//import { makeStyles } from '@material-ui/core';
import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';
import StatusIcon from '@mui/icons-material/CheckCircle';


import lodash from 'lodash';

interface Props {
    source?: string;
    optionValue?: string;
    optionText?: string;
    optionColor?: string;
    url: string;
    useIcon?: boolean;
    value?: string;
}

interface ResItem {
    id: any;
    item_id: any;
    item_title: any;
    item_color?: any;
}

const CustomStatusField: FC<Props> = ({ source = '', optionValue = 'item_id', optionText = 'item_title', optionColor = 'item_color', url, useIcon=true, value = '' }) => {

    const record = useRecordContext();
    let params = '';
    params = '';
    const dataProvider = useDataProvider();
    
    const { data } = useQuery(
        [url, 'getAny', params], 
        () => dataProvider.getAny(url, params)
    );

    if (!data) return null;

    const customChoices = data.data;

    if (!customChoices) return null;

    let val = 0;
    if (value.toString().length > 0)
        val = parseInt(value);
    else
        val = lodash.get(record, source);

    let color = null;
    color = customChoices.filter(cc => {return cc[optionValue] == val;})[0][optionColor] ? customChoices.filter(cc => {return cc[optionValue] == val;})[0][optionColor] : null;
    
    return useIcon ? (<StatusIcon style={color ? {'color':color} : {}} />) : (<span>{customChoices.filter(cc => {return cc[optionValue] == val;})[0][optionText]}</span>);
};

export default CustomStatusField;
