import * as React from 'react';
import {
    CustomRoutes,
    defaultTheme,
    mergeTranslations,
    Resource,
} from 'react-admin';
import { Admin, buildI18nProvider } from '@react-admin/ra-enterprise';
import { addEventsForMutations/* , EventList */ } from '@react-admin/ra-audit-log';
import CssBaseline from '@mui/material/CssBaseline';
import {
    raTreeLanguageEnglish,
    raTreeLanguageFrench,
} from '@react-admin/ra-tree';
import {
    raTourLanguageEnglish,
    raTourLanguageFrench,
} from '@react-admin/ra-tour';
import { Route } from 'react-router';
import { createTheme } from '@mui/material';
import authProvider from './authProvider';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';
import finnishMessages from './i18n/fi';
import { getThemes } from './layout/themes';
import dataProvider from './dataProvider';
import { QueryClient } from 'react-query';
import Configuration from './configuration/Configuration';
import product from './resources/product';
import subscription from './resources/subscription';

const messages = {
    en: mergeTranslations(
        englishMessages,
        raTreeLanguageEnglish,
        raTourLanguageEnglish
    ),
    fr: mergeTranslations(
        frenchMessages,
        raTreeLanguageFrench,
        raTourLanguageFrench
    ),
    fi: mergeTranslations(
        finnishMessages,
        raTreeLanguageEnglish, //täytyy selvittää löytyykö suomea
        raTourLanguageEnglish
    ),
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000, // 1 minutes
        },
    },
});

const i18nProvider = buildI18nProvider(messages, 'fi');

const enhancedDataProvider = addEventsForMutations(dataProvider, authProvider);
const App = () => {

    const theme = createTheme(defaultTheme);
    const { darkTheme, lightTheme } = getThemes(theme);

    return (
        <Admin
            title=""
            dataProvider={enhancedDataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            lightTheme={lightTheme}
            darkTheme={darkTheme}
            queryClient={queryClient}
        >
            <CustomRoutes>
                <Route path="/configuration" element={<Configuration />} />
            </CustomRoutes>    
            <CustomRoutes noLayout>                    
                <Route path="/oidc-login/:code" element={<Login />} />
            </CustomRoutes>
            <CssBaseline />
            <Resource name="product" {...product} />
            <Resource name="subproduct" />
            <Resource name="subscription" {...subscription} />
            <Resource name="userlicense" />
            <Resource name="logevent" />
        </Admin> 
    );
};

export default App;
